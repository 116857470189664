import { IManagerModel } from "api/user"
import { EmailType, ICampaignDefaults, IClient, INote, IScheduledReport, IScheduledReportRequest } from "entities/interfaces"
import { get, put, post, destroy } from "api/restClient"

export interface IClientRequest {
  name: string
  campaignDefaults: ICampaignDefaults
  billingType: BillingType
  languageCode: string
  currencyCode: string
  webSite?: string | null
  facebookPageId?: string | null
  externalId?: string | null
  groupId?: string | null
  resellerId?: string | null
  theme?: { primaryColor?: string }
  metadata: Record<string, string>
  facebookAdAccountId?: string | null
  tags:string[]
}

export interface IClientAssetResponse {
  clientId: string
  id: string
  path: string
  name: string
  createdAt: string
  contentType: string
  category: string
}

export interface IUploadClientAssetResponse {
  clientId: string
  id: string
  path: string
}

export interface IClientNoteRequest {
  note: string
}

export interface IUpdateClientMediaCategoryRequest {
  category: string
}

export interface IEditFacebookPageRequest {
  page: string
  updateCampaigns: boolean
  updateProspectSources: boolean
}

export enum BillingType {
  Unspecified = "unspecified",
  Monthly = "monthly",
  MonthlyArrears = "monthlyArrears",  
  Quarterly = "quarterly",
  SemiYearly = "semiYearly",
  Yearly = "yearly",
  OnDemand = "onDemand",
  External = "external",
}

export enum SampleEmailType {
  ProspectFound = "prospectFound",
  ProspectOrdered = "prospectOrdered",
  ProspectExtended = "prospectExtended",
  ProspectCampaignLive = "prospectCampaignLive",
  ProspectCampaignEnded = "prospectCampaignEnded",
  ScheduledReport = "scheduledReport",
  SendDraft = "sendDraft",
}

export interface ISampleEmailRequest {
  recipients: string[]
  samples: SampleEmailType[]
}

export const updateEmailClientSettings = (id: string, data: {stopEmailSend:Record<EmailType, boolean>}) => put(`/Client/${id}/stopemailsend`, data)

export const getAll = () => get<IClient[]>("client")

export const getById = (id: string) => get<IClient>(`Client/${id}`)

export const getBySlug = (slug: string) => get<IClient>(`Client/${slug}`)

export const create = (data: IClientRequest) => post<IClient>("client", data)

export const edit = (id: string, data: IClientRequest) => put<IClient>(`/Client/${id}`, data)

export const updateFacebookPage = (id: string, data: IEditFacebookPageRequest) => put(`/Client/${id}/facebook`, data)

export const updateClientLogo = (id: string, file: FormData) =>
  post<IClientAssetResponse>(`Client/${id}/logo`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const getMediaLibrary = (id: string) => get<IClientAssetResponse[]>(`/Client/${id}/medialibrary/all`)

export const getScheduledReports = (id: string) => get<IScheduledReport[]>(`/Client/${id}/scheduled-reports`)

export const getAllClientManagers = (id: string) => get<IManagerModel[]>(`/Client/${id}/managers`)

export const getAllClientNotes = (id: string) => get<INote[]>(`/Client/${id}/notes`)

export const addManagerOnClient = (clientId: string, userId: string) => put(`/Client/${clientId}/managers/${userId}`)

export const createNoteOnClient = (clientId: string, data: IClientNoteRequest) => post(`/Client/${clientId}/notes`, data)

export const createScheduledReportOnClient = (clientId: string, data: IScheduledReportRequest) =>
  post(`/Client/${clientId}/scheduled-reports`, data)

export const updateAssetCategory = (clientId: string, assetId: string, data: IUpdateClientMediaCategoryRequest) =>
  put(`/client/${clientId}/medialibrary/${assetId}/category`, data)

export const destroyClientAsset = (clientId: string, assetId: string) => destroy(`/Client/${clientId}/medialibrary/${assetId}`)

export const destroyClientManager = (clientId: string, managerId: string) => destroy(`/Client/${clientId}/managers/${managerId}`)

export const destroyClientNote = (clientId: string, noteId: string) => destroy(`/Client/${clientId}/notes/${noteId}`)

export const mediaLibraryBatch = (clientId: string, category: string, files: FormData) =>
  post<IUploadClientAssetResponse[]>(`client/${clientId}/medialibrary-batch/${category}`, files, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const sendSampleEmail = (clientId: string, data: ISampleEmailRequest) =>
  post(`/Client/${clientId}/send-sample-email`, data)
