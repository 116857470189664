import { addDays, addHours, startOfDay } from "date-fns"
import { AdChannelType } from "entities/enums"
import { IBudgetPeriodRequest, ISubscriptionSettings } from "entities/interfaces"
import { createEndDate } from "utils/dateHelpers"

export const DefaultChannelShare: Record<AdChannelType, number> = { facebook: 0, bidTheatre: 0, youTube: 0, youTubeGoogleAds: 0 }

export const DefaultSubscriptionSettings: ISubscriptionSettings = {
  january: { amount: 0, channels: DefaultChannelShare },
  february: { amount: 0, channels: DefaultChannelShare },
  march: { amount: 0, channels: DefaultChannelShare },
  april: { amount: 0, channels: DefaultChannelShare },
  may: { amount: 0, channels: DefaultChannelShare },
  june: { amount: 0, channels: DefaultChannelShare },
  july: { amount: 0, channels: DefaultChannelShare },
  august: { amount: 0, channels: DefaultChannelShare },
  september: { amount: 0, channels: DefaultChannelShare },
  october: { amount: 0, channels: DefaultChannelShare },
  november: { amount: 0, channels: DefaultChannelShare },
  december: { amount: 0, channels: DefaultChannelShare },
}

export const defaultBudgetPeriod: IBudgetPeriodRequest = {
  name: "",
  amount: 0,
  startDate: addDays(addHours(startOfDay(new Date()), 8), 1),
  endDate: createEndDate(14),
  channelShares: DefaultChannelShare,
}
