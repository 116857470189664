import { BillingType, SampleEmailType } from "api/client"
import { IGroup } from "api/group"
import { put, get, post, destroy } from "api/restClient"
import { AvailableCategoriesType } from "entities/enums"
import { IClient, IVat } from "entities/interfaces"
import { IUserModel } from "api/user"

export enum EmailType {
  OrderCampaignCreate = "orderCampaignCreate",
  OrderCampaignExtend = "orderCampaignExtend",
  CampaignLive = "campaignLive",
  CampaignEnd = "campaignEnd",
}

export enum AdAccountStrategyType {
  Group = "group",
  Client = "client",
  Tenant = "tenant",
}

export interface ITenant {
  id: string
  name: string
  isSandboxed: boolean
  defaultGroupId: string
  vat: IVat
  brokerageFee: number
  billingType: BillingType
  channels: ITenantChannels
  emailTemplate: IEmailTemplate | null
}

export interface ITenantChannels {
  facebook: IFacebookTenantChannel
}
export interface IFacebookTenantChannel {
  adAccountStrategy: AdAccountStrategyType
  adAccountId?: string | null
  fallbackPageId?: string | null
  dsaBeneficiary?: string | null
  dsaPayor?: string | null
}

export interface ITenantMediaResponse {
  id: string
  tenantId: string
  name: string
  contentType: string
  contentLength: number
  path: string
  tags: Record<string, string[]>
  videoUrl?: string
  hasVideoUrl?: boolean
  category?: string
}

export interface ITenantTagsResponse {
  [locale: string]: string[]
}

interface IUploadMediaResponse {
  id: string
  url: string
}

export interface ICreateCategoryRequest {
  tenantId: string
  locale: string
  categories: ICategory[]
}

export interface ICategory {
  name: string
  tags: string[]
}

export interface ICategoriesResponse {
  id: string
  tenantId: string
  locale: string
  categories: ICategory[]
}

export interface IEmailTemplateLanguageItem {
  subject: string
  markdownContent: string
}

export interface IEmailTemplateLanguage {
  supportBox: string
  footer: string
  prospectFound: IEmailTemplateLanguageItem | null
  prospectOrdered: IEmailTemplateLanguageItem | null
  prospectExtended: IEmailTemplateLanguageItem | null
  prospectCampaignLive: IEmailTemplateLanguageItem | null
  prospectCampaignEnded: IEmailTemplateLanguageItem | null
  scheduledReport: IEmailTemplateLanguageItem | null
}

export interface IEmailTypeSelection {
  prospecting: Record<EmailType, boolean>
  integrationModule: Record<EmailType, boolean>
  partnerApi: Record<EmailType, boolean>
}

export interface IEmailTemplate {
  headerFont: string
  contentFont: string
  languages: Record<string, IEmailTemplateLanguage>
  typeSelection: IEmailTypeSelection
}

export interface IEmailTemplateSettingsRequest {
  headerFont: string
  contentFont: string
  typeSelection: IEmailTypeSelection
}

export interface IEmailTemplateInfoRequest {
  supportBox: string
  footer: string
}

export interface IEmailTemplateTypeRequest {
  subject: string
  markdownContent: string
}

export interface ITenantSettings {
  appDomain?: string | null
  corsAllowedDomains: string[]
  cdnDomain?: string | null
  emailDomain?: string | null
  fromEmail?: string | null
  productTitle?: string | null
  smsSender?: string | null
  orderRecipient?: string | null
  cpmSettings: ICpmSettings
  color?: string | null
  language?: string | null
  apiDomain?: string | null
  defaultCategory?: AvailableCategoriesType
  supportEmail?: string | null
  allowedApiChannels: string[]
}

export interface ICpmSettings {
  value: number
  min?: number | null
  max?: number | null
}

export interface ICreateTenantRequest {
  name: string
  isSandboxed?: boolean
  settings: ITenantSettings
  brokerageFee?: number | null
  billingType: BillingType
  channels: ITenantChannels
}

export interface IEditTenantRequest {
  settings: ITenantSettings
  brokerageFee?: number | null
  billingType: BillingType
  channels: ITenantChannels
}

export interface ITenantBranch {
  id: string
  tenantId: string
  name: string
  languageCode: string | null
  currencyCode: string | null
  cpm: number | null
  brokerageFee: number | null
  fallbackFacebookPageId: string | null
}

export interface IUpdateTenantBranchRequest {
  languageCode: string | null
  currencyCode: string | null
  cpm?: number | null
  brokerageFee?: number | null
  fallbackFacebookPageId?: string | null
}

interface INewApiKey {
  accessKey: string
}

interface INewInsightKey {
  valid: boolean
  value: string
}

export const getCategoriesForClient = (id: string) => get<ICategoriesResponse[]>(`/tenant/${id}/category`)

export const getAll = () => get<ITenant[]>("Tenant")

export const getById = (id: string) => get<ITenant>(`/Tenant/${id}`)

export const getGroups = (id: string) => get<IGroup[]>(`/Tenant/${id}/groups`)

export const getClients = (id: string) => get<IClient[]>(`/Tenant/${id}/clients`)

export const getUsers = (id: string) => get<IUserModel[]>(`/Tenant/${id}/users`)

export const getBranches = (id: string) => get<ITenantBranch[]>(`/Tenant/${id}/branches`)

export const getAllMedia = (id: string) => get<ITenantMediaResponse[]>(`/Tenant/${id}/media`)

export const getAllTenantMediaOnClient = (id: string) => get<ITenantMediaResponse[]>(`/Tenant/client/${id}/media`)

export const getLocaleTags = (tenantId: string, clientId: string, locale: string) =>
  get<string[]>(`/Tenant/${tenantId}/client/${clientId}/media/tags/locale/${locale}`)

export const getTagsForTenant = (tenantId: string) => get<ITenantTagsResponse>(`/Tenant/${tenantId}/media/tags`)

export const destroyMedia = (tenantId: string, mediaId: string) => destroy(`/Tenant/${tenantId}/media/${mediaId}`)

export const setTagsOnMedias = (tenantId: string, mediaIds: string[], tags: ITenantTagsResponse) =>
  post(`/Tenant/${tenantId}/media/tags`, { mediaIds, tags })

export const createMediaCategory = (category: ICreateCategoryRequest) => post("/tenant/media/categories", category)

export const uploadMedias = (tenantId: string, files: File[]) => {
  const formData = files.reduce((acc, file) => {
    acc.append("files", file)
    return acc
  }, new FormData())

  return post<IUploadMediaResponse[]>(`/Tenant/${tenantId}/medias`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const uploadTenantLogos = (host: string, imageType: "logo" | "logo-inverted" | "favicon", formData: FormData) => {
  return post<IUploadMediaResponse[]>(`/Tenant/${imageType}?host=${host}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const updateEmailTemplate = (id: string, data: IEmailTemplate) => put(`/Tenant/${id}/templates/email`, data)

export const updateEmailTemplateSettings = (id: string, data: IEmailTemplateSettingsRequest) =>
  put(`/Tenant/${id}/templates/email/settings`, data)

export const updateEmailTemplateInfo = (id: string, language: string, data: IEmailTemplateInfoRequest) =>
  put(`/Tenant/${id}/templates/email/${language}`, data)

export const updateEmailTemplateType = (id: string, language: string, type: SampleEmailType, data: IEmailTemplateTypeRequest) =>
  put(`/Tenant/${id}/templates/email/${language}/${type}`, data)

export const updateBranch = (tenantId: string, branchId: string, data: IUpdateTenantBranchRequest) =>
  put(`/Tenant/${tenantId}/branches/${branchId}`, data)

export const create = (data: ICreateTenantRequest) => post<ITenant>("/Tenant", data)

export const edit = (id: string, data: IEditTenantRequest) => put(`/Tenant/${id}`, data)

export const createApiKey = (id: string) => post<INewApiKey>(`/Tenant/${id}/accesskey`)

export const createInsightKey = (id: string) =>
  post<INewInsightKey>(`/Tenant/${id}/derivedkey`, {
    resource: "insights",
  })

export const insightsSampleData = (id: string) => post(`/Tenant/${id}/sample/insights`)
