import { ChannelProfile } from "api/campaign"
import {
  IAdCreateTemplateChannel,
  IAsset,
  IComposition,
  ICreateTemplateRequest,
  IDataSourceData,
  ITemplate,
} from "entities/interfaces"
import { IRenameRequest } from "entities/interfaces"
import { get, put, post, destroy } from "api/restClient"
import { TemplateChannel } from "entities/enums"

interface IEvaluateProps {
  missingFields: []
  resolvedData: IDataSourceData
}

export interface CloneDataProps {
  destinationClientId: string
}

interface CloneTemplateResponseProps {
  id: string
}

export interface IEditTemplateTagsRequest {
  tags: string[]
}

export interface IEditTemplateTextFormatRequest {
  channelProfile: ChannelProfile
  rootFormatters: Record<string, string>
  itemFormatters: Record<string, string>
}

export interface IEditTemplateTextFormatResponse {
  rootFormatters: Record<string, string>
  itemFormatters: Record<string, string>
}

export const getById = (id: string) => get<ITemplate>(`Template/${id}`)

export const create = (data: Partial<ITemplate>) => post<string>(`Template`, data)

export const createNew = (data: ICreateTemplateRequest) => post<{ id: string }>(`Template`, data)

export const clone = (id: string, data: CloneDataProps) => post<CloneTemplateResponseProps>(`Template/${id}/clone`, data)

export const evaluateTemplate = (id: string, currentChannel: string, data?: IDataSourceData) =>
  post<IEvaluateProps>(`/Template/${id}/evaluate/${currentChannel}`, data)

export const edit = (id: string, channel: string, data: Partial<ITemplate>) => put<string>(`/Template/${id}/${channel}`, data)

export const adEdit = (id: string, channel: TemplateChannel, data: IAdCreateTemplateChannel) =>
  put<string>(`/Template/${id}/${channel}`, data)

export const render = (clientId: string, bucketId: string, itemId: string, data: IComposition) =>
  post<any>(`/template/preview/${clientId}/${bucketId}/${itemId}`, data, { responseType: "blob" })

export const getAssets = (id: string) => get<IAsset[]>(`/template/client/${id}/assets`)

export const getAllByClientId = (clientId: string) => get<ITemplate[]>(`Template/client/${clientId}`)

export const updateName = (id: string, data: IRenameRequest) => put(`/Template/${id}/name`, data)

export const activate = (id: string) => post(`/Template/${id}/activate`)

export const deleteUploaded = (companyId: string, assetId: string) => destroy(`/template/client/${companyId}/assets/${assetId}`)

export const deactivate = (id: string) => post(`/Template/${id}/deactivate`)

export const remove = (id: string) => destroy(`/Template/${id}`)

export const editTags = (id: string, data: IEditTemplateTagsRequest) => put(`/Template/${id}/tags`, data)

export const editTextFormat = (id: string, data: IEditTemplateTextFormatRequest) =>
  put(`/Template/${id}/${data.channelProfile}/textformats`, data)

export const getChannelTextFormats = (templateId: string, channelProfile: ChannelProfile) =>
  get<IEditTemplateTextFormatResponse>(`Template/${templateId}/${channelProfile}/textformats`)

export const uploadClientAsset = (clientId: string, file: FormData) =>
  post<IAsset>(`template/client/${clientId}/assets`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  })
