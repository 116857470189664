import { IProspectSourceRequest, IProspectingViewModel, ICreateProspectSourceRequest ,ISpecificEmail } from "entities/interfaces"
import { get, put, post, destroy } from "api/restClient"

export const getById = (id: string) => get<IProspectingViewModel>(`/Prospecting/${id}`)

export const send = (id: string) => post(`/Prospect/${id}/notify`)
 
export const sendToSpecific = (id : string ,data : ISpecificEmail) => post(`/Prospect/${id}/send` , data)

export const activate = (id: string) => post(`/Prospecting/${id}/activate`)

export const deactivate = (id: string) => post(`/Prospecting/${id}/deactivate`)

export const remove = (id: string) => destroy(`/Prospecting/${id}`)

export const edit = (id: string, data: IProspectSourceRequest) => put(`/Prospecting/${id}/configure`, data)

export const create = (data: ICreateProspectSourceRequest) => post(`/Prospecting/request`, data)

export const getAllByClientId = (id: string) => get<IProspectingViewModel[]>(`/Prospecting/client/${id}`)

export const getAllByUserId = (id: string) => get<IProspectingViewModel[]>(`/Prospect/${id}/prospects`)