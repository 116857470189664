import { FacebookPageAccess } from "entities/enums"
import { IFacebookData } from "entities/interfaces"
import { AdProspectType } from "mobxStores/OrderStore"

export const mockedFacebookData: IFacebookData = {
  access: FacebookPageAccess.Approved,
  id: "408657822669515",
  name: "SpiderAds",
  thumbnailUrl: "https://parker.spiderads.eu/client/deee869b-4134-4546-9c96-dd06dac3acee/logo?width=200",
}

export const handleDemoOrderIds = (adProspectType: AdProspectType): string => {
  switch (adProspectType) {
    case AdProspectType.realEstateProspectDemo:
      return "a9985777-3fab-4fc2-a986-ffe425978b0c"
    case AdProspectType.dutchDemo:
    case AdProspectType.finnishDemo:
    case AdProspectType.humanResourcesProspectDemo:
      return "c03e9c92-8df5-43c4-a9ad-41a727e76c74"
    case AdProspectType.carProspectDemo:
      return "5a29f4f1-0e02-42fb-8248-f60b409653c7"
    default:
      return "a9985777-3fab-4fc2-a986-ffe425978b0c"
  }
}
